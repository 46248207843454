import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { array, bool, string } from 'prop-types'
import { makeImgResizerUrl } from '@jelly/utils'

const Wrapper = styled.div`
	border-top: ${({ hasTopBorder, theme }) => (hasTopBorder ? `7px solid ${theme.colors.primary}` : `0`)};
	margin: 1rem 0 2.5rem;
	width: 100%;
`
const FlexRow = styled.div`
	align-items: center;
	display: flex;
`
const HubCardTitle = styled(Link)`
	align-items: center;
	color: ${({ theme }) => theme.colors.black};
	display: flex;
	font-size: 1rem;
	font-weight: bold;
	line-height: 25px;
	margin: 1rem 1rem 1rem 0;
	text-transform: uppercase;

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}

	${({ theme }) => theme.MQ.M} {
		font-size: 1.2rem;
		margin-right: 1rem;
	}
`
const LinksWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;

	${({ theme }) => theme.MQ.M} {
		flex-direction: row;
	}
`
const FeaturedTitle = styled.div`
	font-size: 1.3rem;
	font-weight: bold;
`
const StyledFeaturedLink = styled(Link)`
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 1.25rem;
	margin-right: 0;
	width: 100%;

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}

	${({ theme }) => theme.MQ.M} {
		margin-bottom: 0;
		margin-right: 1rem;
	}
`
const FeaturedThumbnail = styled.img`
	cursor: pointer;
	display: block;
	margin-bottom: 1rem;
	width: 100%;
`
const ArticleTitle = styled(Link)`
	color: ${({ theme }) => theme.colors.black};
	display: block;
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 1.5rem;

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}

	${({ theme }) => theme.MQ.L} {
		font-size: 1.1rem;
	}
`
const OtherLinksWrapper = styled.div`
	width: 100%;
`

HubCard.propTypes = {
	content: array.isRequired,
	hasTopBorder: bool,
	name: string.isRequired,
	slug: string.isRequired,
}

/**
 * HubCard - common component to display latest 5 pieces of content
 * @param {Array} props.content :: content to be displayed
 * @param {String} props.name :: name of the content module
 * @param {String} props.slug :: slug used for the See More link
 *
 **/
export function HubCard({ content, hasTopBorder = true, name, slug }) {
	const firstItem = content[0]
	const restOfItems = content.slice(1, 5)

	return (
		<Wrapper hasTopBorder={hasTopBorder}>
			<FlexRow>
				<HubCardTitle to={`/${slug}`}>{name}</HubCardTitle>
			</FlexRow>
			<LinksWrapper>
				<StyledFeaturedLink to={`/${firstItem._source.href}`}>
					<FeaturedThumbnail
						alt={firstItem._source.title}
						className="lazyload"
						data-src={makeImgResizerUrl({
							src: firstItem._source.thumbnail === null ? undefined : firstItem._source.thumbnail.path,
							method: 'h',
							h: '600-png',
						})}
					/>
					<FeaturedTitle>{firstItem._source.title}</FeaturedTitle>
				</StyledFeaturedLink>
				<OtherLinksWrapper>
					{restOfItems.map(({ _source: { href, title } }, i) => (
						<ArticleTitle key={`title-${i}`} to={`/${href}`}>
							{title}
						</ArticleTitle>
					))}
				</OtherLinksWrapper>
			</LinksWrapper>
		</Wrapper>
	)
}
