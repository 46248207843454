import React from 'react'
import styled from '@emotion/styled'
import { graphql, Link, StaticQuery } from 'gatsby'

// Import Components
import { CheckMark } from '@jelly/components'
import { useTheme } from 'emotion-theming'

const Wrapper = styled.div`
	margin: 1rem 0;
	order: 2;
	width: 100%;

	${({ theme }) => theme.MQ.M} {
		margin: 1rem 2.5rem 1rem 0;
		order: -1;
		width: 300px;
	}
`
const SidebarTitle = styled.h2`
	align-items: center;
	color: #000;
	display: flex;
	margin-bottom: 1rem;
	line-height: 25px;
	font-size: 1.25rem;
	text-transform: uppercase;

	svg {
		margin-left: 0.5rem;
	}
`
const StyledLink = styled(Link)`
	display: block;
	margin-bottom: 2.3rem;
`
const SubhubTitle = styled.div`
	color: ${({ theme }) => theme.colors.primary};
	font-size: 0.8rem;
	text-transform: uppercase;
`
const HubTitle = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-size: 1rem;
	font-weight: bold;

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
`

export function Sidebar() {
	const {
		colors: { primary },
	} = useTheme()
	return (
		<StaticQuery
			query={graphql`
				query SidebarQuery {
					content(
						limit: 10
						query: {
							terms: {
								href__keyword: [
									{ value: "6730-best-time-and-attendance-systems.html" }
									{ value: "7509-best-payroll-services.html" }
									{ value: "6780-best-business-phone-systems.html" }
									{ value: "7839-best-crm-software.html" }
									{ value: "8448-best-business-loans.html" }
									{ value: "7543-best-accounting-software.html" }
									{ value: "2955-best-pos-systems.html" }
									{ value: "8061-best-credit-card-processing.html" }
									{ value: "11143-best-employee-monitoring-software.html" }
									{ value: "15044-best-text-message-marketing-solutions.html" }
								]
							}
						}
					) {
						count
						hits {
							_source {
								href
								title
								channels {
									sub {
										name
									}
								}
							}
						}
					}
				}
			`}
			render={({ content: { hits } }) => {
				return (
					<Wrapper>
						<span>
							<SidebarTitle>
								Our Best Picks
								<CheckMark fill={primary} />
							</SidebarTitle>
						</span>
						{hits.map(({ _source: { href, title, channels: { sub } } }, i) => (
							<>
								<SubhubTitle>{sub.name}</SubhubTitle>
								<StyledLink key={`title-${i}`} to={`/${href}`}>
									<HubTitle>{title}</HubTitle>
								</StyledLink>
							</>
						))}
					</Wrapper>
				)
			}}
		/>
	)
}
