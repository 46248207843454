import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { array } from 'prop-types'

const Wrapper = styled.div`
	${({ theme }) => theme.maxWidth.L};
	display: none;
	margin: 1rem auto;
	padding: 1rem 1.875rem;

	${({ theme }) => theme.MQ.M} {
		display: block;
	}
`
const LinksWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`
const TitlesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 0.5rem;

	&:first-child {
		padding-left: 0;
	}
	&:last-child {
		padding-right: 0;
	}
`
const HubTitle = styled.p`
	color: ${({ theme }) => theme.colors.primary};
	margin-bottom: 1.25rem;
	text-transform: uppercase;
`
const SubLink = styled(Link)`
	color: ${({ theme }) => theme.colors.black};
	display: block;
	font-size: 0.8rem;
	margin-bottom: 0.875rem;

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
`

HubLinks.propTypes = {
	hubs: array.isRequired,
}

/**
 * HubLinks - used to display the list of hubs and corresponding subhubs
 * @param {Array} props.hubs :: hubs to be displayed
 *
 **/
export function HubLinks({ hubs }) {
	return (
		<Wrapper>
			<LinksWrapper>
				{hubs.map((hub) => {
					const subhubs = hub.subHubs.map((sub) => {
						return <SubLink to={`/${sub.slug}`}>{sub.title}</SubLink>
					})
					return (
						<TitlesWrapper>
							<HubTitle>{hub.fullTitle}</HubTitle>
							{subhubs}
						</TitlesWrapper>
					)
				})}
			</LinksWrapper>
		</Wrapper>
	)
}
